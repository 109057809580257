<template>
  <span>
    <v-dialog
      v-model="dialog"
      width="500"
    >

      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="deleteButtonLg"
          v-if="!view || view === 'big'"
          color="dark-grey"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Remover
        </v-btn>
    
        <v-btn
          id="deleteButtonSm"
          v-if="view === 'small'"
          fab
          dark
          small
          color="dark-grey"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>
            mdi-delete
          </v-icon>
        </v-btn>


      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          Essa ação não pode ser desfeita.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          
          <delete-button
            @remove="submit()"
          ></delete-button>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  const {EventBus} = require('../addons/event-bus.js')
  import DeleteButton from './DeleteButton.vue'
  export default {
    name: 'DeleteDialog',
    components: {DeleteButton},
    props: ['pk', 'view', 'event'],
    data () {
      return {
        dialog: false
      }
    },
    methods: {
      submit() {
        const event = this.event ? this.event : ''
        EventBus.$emit(event, this.pk)
        this.dialog = false
      }
    }
  }
</script>