<template>
    <span>
        <v-btn
          id="viewButtonLg"
          v-if="!view || view === 'big'"
          :color="$store.getters.palette.primary"
          dark
          @click="$router.push({ path: path })"
        >
          Ver
        </v-btn>
    
        <v-btn
          id="viewButtonSm"
          v-if="view === 'small'"
          class="mx-2"
          fab
          dark
          x-small
          :color="$store.getters.palette.primary"
          @click="$router.push({ path: path })"
        >
          <v-icon dark x-small>
            mdi-eye
          </v-icon>
        </v-btn>
    </span>
</template>


<script>
  export default {
    name: 'ViewButton',
    props: ['path', 'view']
  };
</script>
