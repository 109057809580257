const {EventBus} = require('./event-bus.js')

const defaultComputed = {
  data() {
    return {
      'addAction': 'addEntity',
      'updateAction': 'updateEntity',
      'removeAction': 'removeEntity',
      'loadAction': 'getEntity',
      'loadAllAction': 'getEntities',
    }
  },
  computed: {
      title: function () {
        if (this.item && this.view === 'create' && !this.pk) {
          return 'Adicionar novo ' + this.name
        }
        if (this.item && this.view === 'edit' && this.pk) {
          return 'Editando '  + this.name + ' ' + this.item.case 
        }
        if (this.item && this.view === 'view' && this.pk) {
          return  this.name + ' ' + this.item.case 
        }
        return this.plural_name
      },
      pk: function() {
        return this.$route.params.id
      },
      view: function() {
        return this.$route.params.action
      },
      roles: function() {
        return this.$store.getters.profileRoles
      },
      showForm: function () {
        return this.item &&
            (this.view === 'edit' && this.pk) ||
            (this.view === 'create' && !this.pk)
      },
      showTable: function () {
        return this.items && !this.view
      },
      showDetail: function () {
        return this.item && this.view === 'view'
      },
      showCodeEditor: function () {
        return this.view === 'dev' && this.pk
      },
      raw_items: function() {
        return this.$store.getters.findAll(this.entity)
      },
      items: function() {
        return this.$store.getters.findAll(this.entity)
      },
      raw_item: function() {
        if (this.pk) {
          return this.$store.getters.findOne(this.entity, this.pk)
        }
        return {}        
      },
      item: function() {
        if (this.pk) {
          return this.$store.getters.findOne(this.entity, this.pk)
        }
        return {}
      },
  }
}

const routeMethods = {
  watch: {
    $route (to, from) {
      if (to.name != from.name) {
        this.runLoad(to)
      } 
      this.runRouteLoad(to, from)
    },
    view() {
      EventBus.$emit(this.entity + '-view-changed')
    }
  },
  methods: {    
    runRouteLoad(to, from) {
      if (to.name === from.name && this.$route.params.id) {
        const entity = this.entity
        const pk = this.$route.params.id
        this.$store.commit('selectEntity', {prefix: entity, pk})
        this.hook('onEntitySelected')
      }
      if (
        to.name === from.name &&
        !this.view &&
        this.$store.state.entities[this.entity] && this.$store.state.entities[this.entity].items.length === 0 &&
        !this.$store.state.entities[this.entity] && this.$store.state.entities[this.entity].isCollectionEmpty
      ) {
        this.loadAll()
      }
    },
    autoreload() {
      return null
    },
    reload() {
      if (this.$route.params.id) {
        this.load()
      }
      this.loadAll()
      this.hook('onReload')
    },
    runLoad(route) {
      if (!route) {
        route = this.$route 
      }
      if (route.params.action === 'create') {
        return
      }
      if (route.params.id) {
        this.load()
        if (this.items.length === 0) {
          this.loadAll()
        }
      } else {
        this.loadAll()
      }
      this.hook('onLoad')
    },
  }
}

const routeEvents = {
  mounted() {
    EventBus.$on(this.entity + '-created', this.reload)
    EventBus.$on(this.entity + '-updated', this.reload)
  },
  beforeDestroy() {
    EventBus.$off(this.entity + '-created')
    EventBus.$off(this.entity + '-updated')
  }
}

const defaultMethods = {
  methods: {
    hook(name, ...params) {
      if (this[name]  && typeof this[name].call === 'function') {
        this[name](...params)
      }
    },
    onError(err) {
        EventBus.$emit('notification', err)
        return err
    },
    onReady(r) {
        this.$store.commit('setReady')
        return r
    },
    onRemoved(r) {
        this.$store.commit('setReady')
        if (r.status < 300) {
          if (this.redir) {
            this.$router.push({path: this.redir})
          } else {
            this.$router.go(-1)
          }
        }
        return r
    },
    remove(pk) {
      this.hook('onBeforeRemove', {pk})
      let entity = this.entity
      let params = {pk}
      if (this.removeAction == 'removeEntity') {
        params = {prefix: entity, pk}
      }
      const p1 = this.$store.dispatch(this.removeAction, params)
      return p1.then(this.onRemoved)
    },
    push() {
      const entity = this.entity
      const pk = this.pk
      this.$store.commit('pushEntity', {prefix: entity, pk})
    },
    submit: function (raw=false) {
      let action = this.addAction
      const item = raw ? this.raw_item : this.item
      const processed = this.processed(item)
      this.schema
        .validate(processed)
        .then((valid) => {
          this.hook('onValid')
          let params = {item: processed}
          if (action == 'addEntity') {
            params.prefix = this.entity 
            this.hook('onBeforeCreate')
          }
          if (this.$route.params.action === 'edit') {
            action = this.updateAction
            params = {item: processed, pk: this.$route.params.id}
            if (action == 'updateEntity') {
              params.prefix = this.entity 
            }
            this.hook('onBeforeUpdate')
          }
          const p1 = this.$store.dispatch(action, params)
          p1.then(r => {
            this.$store.commit('setReady')
            if (r.status < 300) {
              if (action === this.updateAction) {
                return r
              } else if (this.redir) {
                this.$router.push({path: this.redir})
              } else {
                this.$router.go(-1)
              }
            }
            return r
          })
          return valid
        })
        .then((r) => {
          this.hook('onSaved', {response: r})
          if (this.$route.params.action === 'create') {
            this.hook('onCreated')
          }
          if (this.$route.params.action === 'edit') {
            this.hook('onUpdated')
          }
          return r
        })
        .catch(this.onError)
    },
    search(filters) {
      this.hook('onBeforeLoadAll')
      const p1 = this.$store.dispatch(this.loadAllAction, filters)
      return p1.then(this.onReady).then((r) => {
        this.hook('onLoadAll')
        return r
      })
    },
    loadAll() {
      this.hook('onBeforeLoadAll')
      let params = null
      if (this.loadAllAction == 'getEntities') {
        const entity = this.entity
        params = {prefix: entity}
      }
      const p1 = this.$store.dispatch(this.loadAllAction, params)
      return p1.then(this.onReady).then((r) => {
        this.hook('onLoadAll')
        return r
      })
    },
    load() {
      this.hook('onBeforeLoad')
      const pk = this.$route.params.id
      let params = {pk}
      if (this.loadAction == 'getEntity') {
        const entity = this.entity
        params = {prefix: entity, pk}
      }
      const p1 = this.$store.dispatch(this.loadAction, params)
      return p1.then(this.onReady).then((r) => {
        this.hook('onLoad')
        return r
      })
    }
  }
}

const defaultEvents = {
  mounted() {
    EventBus.$on(this.entity + '-removal-submitted', this.remove)
    EventBus.$on(this.entity + '-search-submitted', this.search)
    EventBus.$on(this.entity + '-submitted', this.submit)
  },
  beforeDestroy() {
    EventBus.$off(this.entity + '-search-submitted')
    EventBus.$off(this.entity + '-removal-submitted')
    EventBus.$off(this.entity + '-submitted');
  },  
}


export {
  defaultComputed,
  defaultMethods,
  defaultEvents,
  routeMethods,
  routeEvents,
}
