var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"md":"1"}},[(_vm.role != 'legal')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/'+ _vm.entity + '/create'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,1890360972)},[_c('span',[_vm._v("Novo")])]):_vm._e(),_c('br'),_c('br'),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/'+ _vm.entity + '/'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-table ")])],1)]}}])},[_c('span',[_vm._v("Ver todos")])]),_c('br'),_c('br'),(_vm.$route.params.id)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"editUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/' + _vm.entity + '/edit/' + _vm.$route.params.id}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,false,3654359653)},[_c('span',[_vm._v("Editar")])]):_vm._e(),_c('br'),_c('br'),(_vm.$route.params.id)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"editUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/' + _vm.entity + '/view/' + _vm.$route.params.id}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,false,1435803568)},[_c('span',[_vm._v("Detalhe")])]):_vm._e(),_c('br'),_c('br'),(_vm.$route.params.id && _vm.admin)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"editUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/' + _vm.entity + '/dev/' + _vm.$route.params.id}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-code-json ")])],1)]}}],null,false,2485417579)},[_c('span',[_vm._v("Código")])]):_vm._e(),_c('br'),_c('br'),(_vm.$route.params.id && _vm.role === 'internal')?_c('delete-dialog',{staticStyle:{"margin-left":"10px"},attrs:{"view":"small","event":_vm.entity + '-removal-submitted',"pk":_vm.$route.params.id}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }