<template>
        <v-col
          md="1"
        >
                <v-tooltip v-if="role != 'legal'" right>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/'+ entity + '/create'"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                  </template>
                  <span>Novo</span>
                </v-tooltip>
                <br>
                <br>

                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/'+ entity + '/'"
                      >
                        <v-icon dark>
                          mdi-table
                        </v-icon>
                      </v-btn>
                  </template>
                  <span>Ver todos</span>
                </v-tooltip>

                <br>
                <br>
                
                <v-tooltip v-if="$route.params.id" right>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="editUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/' + entity + '/edit/' + $route.params.id"
                      >
                        <v-icon dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <br>
                <br>
                
                <v-tooltip v-if="$route.params.id" right>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="editUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/' + entity + '/view/' + $route.params.id"
                      >
                        <v-icon dark>
                          mdi-eye
                        </v-icon>
                      </v-btn>
                  </template>
                  <span>Detalhe</span>
                </v-tooltip>

                <br>
                <br>
                
                
                <v-tooltip v-if="$route.params.id && admin" right>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="editUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/' + entity + '/dev/' + $route.params.id"
                      >
                        <v-icon dark>
                          mdi-code-json
                        </v-icon>
                      </v-btn>
                  </template>
                  <span>Código</span>
                </v-tooltip>

                <br>
                <br>
                
               <delete-dialog
                  v-if="$route.params.id && role === 'internal'"
                  style="margin-left:10px"
                  view="small"
                  :event="entity + '-removal-submitted'"
                  :pk="$route.params.id"
               ></delete-dialog>

        </v-col>
</template>
<script>
  import DeleteDialog from './DeleteDialog.vue'
  export default {
    components: {
      DeleteDialog
    },
    props: ['entity'],
    computed: {
      roles: function() {
        return this.$store.getters.profileRoles
      },
      role: function() {
        if (this.roles && this.roles.map(i => {return i.value}).includes('lawyer')) {
          return 'legal'
        }
        return 'internal'
      },
      admin: function() {
        if (this.roles && this.roles.map(i => {return i.value}).includes('admin')) {
          return true
        }
        return false
      },
    }
}
</script>
