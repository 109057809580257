<template>
    <span>
        <v-btn
          id="deleteButtonLg"
          v-if="!view || view === 'big'"
          color="dark-grey"
          dark
          @click="$emit('remove')"
        >
          <v-icon dark>
            mdi-delete
          </v-icon> Remover
        </v-btn>
    
        <v-btn
          id="deleteButtonSm"
          v-if="view === 'small'"
          class="mx-2"
          fab
          dark
          small
          color="dark-grey"
          @click="$emit('remove')"
        >
          <v-icon dark>
            mdi-delete
          </v-icon>
        </v-btn>

  </span>

</template>


<script>
  export default {
    name: 'DeleteButton',
    props: ['view']
  }
</script>
