<template>
    <div>
        <v-chip
            small
            dark
            v-if="status"
            :color="status.color"
        >
            <v-icon small left v-if="status.icon">
                {{status.icon}}
            </v-icon>
            {{status.name}}
        </v-chip>
        <v-chip
            v-if="!status"
        >
            {{dictionaryKey}}
        </v-chip>
        
    </div>
</template>
<script>
    export default {
        name: 'StatusTableDisplay',
        props: ['dictionaryKey'],
        computed: {
            status: function () {
                return this.$store.getters.dictionary[this.dictionaryKey]
            },
        },
    }
</script>
